.kit-cards-container {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    
}

.dashboard-header {
  color: #0B54A4;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.header {
  position: absolute;
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  top: 38px;
  left: 7%;
}

.card-bg{
  position: absolute;
  width: 363px;
  height: 247px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.bottom-right-image {
  position: absolute;  /* Absolute positioning */
  bottom: 0;           /* Align to the bottom */
  right: 0;            /* Align to the right */

}

.card-name{
  color: rgba(0, 0, 0, 0.57);
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.modal-body {
  margin-top: 85px;
}
.modal-title{
  position: relative;
  left: -107px;
  top: -110px;
  color: #FFF;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.modal-form {
  padding: 0px 61px 0px 61px;
}

.modal-form-margin {
  margin-left: 30px;
}
.header-h2{
  color: #1F1F1F;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 50px;
}
/* styles.css */

/* Mobile and smaller devices */

  
  /* Gradient background for the modal content */
  .modal-content {
    border-radius: 16px;
    min-height: 537px;
    min-width: 450px;
  }

  .image-kit {
    position: absolute;
    max-width: none;
    right: 150px;
  }

  .image-add-kit {
    position: absolute;
    max-width: none;
    right: 150px;
    top: 58px;
  }
.modal-added-h3{
  color: rgba(255, 255, 255, 0.80);
    text-align: center;
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    position: absolute;
    top: 355px;
    left: 180px;
}

.modal-added-btn {
    display: flex;
    width: 400px;
    padding: 16px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #FFF;
    position: absolute;
    top: 450px;
    left: 120px;
}

.image-tick {
  position: absolute;
  top: 353px;
  left: 150px;
}

.modal-added-p {
  position: absolute;
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  top: 400px;
  left: 45px;
}

  .modal-content-gradient {
    background: radial-gradient(100.00% 100.00% at 50.00% 100.00%, #4DBFFF 0%, #0060B9 100%);
    padding: 140px;
    border-radius: 9px;
  }

  .modal-content-gradient-added{
    background: radial-gradient(100.00% 100.00% at 50.00% 100.00%, #4DBFFF 0%, #0060B9 100%);
    border-radius: 6px;
    min-height: 537px;
    min-width: 450px;
  }
  
  /* Close button for the modal */
  .close-modal-button {
    position: absolute;
    right: 20px;
    top: 10px;
    background: transparent;
    border: none;
    font-size: 1.5em;
    color: white; /* Adjust as needed for visibility against the gradient */
    cursor: pointer;
  }


  .video-player-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    position: relative;
  }
  
  .video-wrapper {
    position: absolute;
    top: 15%;
    left: 1%;
    width: 65%;
    height: 75%;
  }
  
  .video-wrapper video {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 15px;
  }

  .thumbnail-pdf-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    width: 90%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .thumbnail-video-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    width: 90%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .thumbnail-video-item img {
    width: 135px;
    height: 95px;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
  }

  .thumbnail-pdf-item img {
    width: 100px;
    height: 70px;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
  }
  
  .thumbnail-video-item .description {
    flex: 1;
    padding: 8px;
    border-radius: 0 10px 10px 0;
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .thumbnail-pdf-item .description {
    flex: 1;
    padding: 8px;
    border-radius: 0 10px 10px 0;
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .video-list {
    position: absolute;
    left: 68%;
    top: 40%;
    max-height: 400px;
    min-width: 33%;
    overflow-y: auto;
  }

  .videos-heading{
    position: absolute;
    left: 68%;
    top: 35%;
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  .pdf-heading{
    position: absolute;
    left: 68%;
    top: 15%;
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  .video-heading{
    position: absolute;
    left: 2%;
    top: 95%;
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  .pdf-list {
    position: absolute;
    left: 68%;
    width: 32%;
    top: 20%;
    z-index: 999;
    min-width: 30%;
    cursor: pointer;
  }

.back-button {
  position: absolute;
  top: 5%;
  left: 3%;
  width: 25px;
  z-index: 1;
  cursor: pointer;
}
  
  

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

@media (max-width: 768px) {
  .custom-modal {
    max-width: 100%;
    margin: 0;
    height: 100vh;
    border-radius: 0;
  }
}
.highlight{
  color: #0B54A4;
  background-image: linear-gradient(to right, #0B54A44A, #0B54A400);
}

.highlight svg{
  fill: #0B54A4;
}