.App {
  text-align: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sidebar-divider {
  border-bottom: 1px solid #ffffff26;
  margin: 0 8% 8%;
}

.navbar-divider {
  border-left: 1px solid #858796;
}

.sidebar-heading {
  color: #ffffff66;
  padding: 0 8%;
  font-weight: 800;
  font-size: 1rem;
}

.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
}

.accordion {
  width: 100%;
  margin: 2rem auto;
}

.login-container {
  width: 100%;
}

.main-sidenav-bar-wrp {
  width: 100%;
}

/* width */
.cm-dropdown-scroll::-webkit-scrollbar {
  width: 2px;
}

.cm-bottom-scrollbar::-webkit-scrollbar {
  height: 4px;
}

/* Track */
.cm-dropdown-scroll::-webkit-scrollbar-track,
.cm-bottom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cm-dropdown-scroll::-webkit-scrollbar-thumb,
.cm-bottom-scrollbar::-webkit-scrollbar-thumb {
  background: #103256;
}

/* Handle on hover */
.cm-dropdown-scroll::-webkit-scrollbar-thumb:hover,
.cm-bottom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cm-email-verify-wrap-info .flex div {
  width: 100%;
}

@media (min-width: 640px) {
  .login-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .login-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .login-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .login-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .login-container {
    max-width: 1536px;
  }
}

@media (min-width: 2560px) {
  .login-container {
    max-width: 2560px;
  }
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}


.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #fff;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.form-error {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

input,
textarea,
select {
  background-color: #fff;
}

select option {
  color: #000 !important;
}

.rdt_TableHead .rdt_TableCol {
  background-color: #F3F4F6;
}

.rdt_Pagination div>svg {
  display: none;
}

.main-sidenav-bar-wrp>div {
  background: transparent;
}

.cm-admin-multi-menu,
.cm-admin-multi-menu svg {
  font-weight: bold;
  /* font-family: cursive; */
  color: #A3A3A3;
  fill: #A3A3A3;
}

.cm-admin-multi-menu:hover,
.cm-admin-multi-menu:hover svg {
  color: #0B54A4;
  fill: #0B54A4;

}

.cm-admin-sidebar-link.admin-no-child>span {
  padding: 0;
  font-weight: bold;
  /* font-family: cursive; */
}

.cm-menu-link {
  cursor: pointer;
  font-weight: bold;
  /* font-family: cursive; */
  color: #A3A3A3;
  fill: #A3A3A3;
}

.cm-menu-link:hover {
  background-image: linear-gradient(to right, #0B54A44A, #0B54A400);

}

.cm-admin-sidebar-link:hover svg {
  fill: #0B54A4;
}

/* Landing Page CSS */
#carouselBox img {
  object-fit: cover;
  /* object-fit: contain; */
  height: 300px;
}

/* Admin Dashboard Css Start*/
/* .cm-admin-category-table-wrapper .rdt_TableCell:first-child {
  max-width: fit-content;
}

*/
.cm-admin-category-table-wrapper .rdt_TableCell,
.cm-admin-category-table-wrapper .rdt_TableHead .rdt_TableCol {
  max-width: 100%;
}

.cm-kits-form-title {
  font-size: 22px;
  font-weight: 600;
}

.cm-admin-category-table-wrapper .rdt_TableHead .rdt_TableCol:first-child,
.cm-admin-category-table-wrapper .rdt_TableCell:first-child {
  max-width: 100px;
}

form.cm-form-add .cm-select-bg-remove {
  background: no-repeat;
}

.cm-print-data p {
  page-break-after: avoid;
  page-break-before: always;
  height: 100vh;
}

.cm-print-data p img {
  margin: 0 auto;
}

.cm-print-data p:first-child {
  page-break-before: avoid;
}

@media only screen and (min-width: 767.98px) {
  .cm-logged-ftr {
    width: calc(100% - 300px);
    margin-left: auto;
  }
}

@media only screen and (max-width: 1024px) {

  .cm-admin-category-table-wrapper .rdt_TableHead .rdt_TableCol:last-child,
  .cm-admin-category-table-wrapper .rdt_TableCell:last-child {
    min-width: 200px;
  }
}

/* Admin Dashboard Css END*/

@media only screen and (min-device-width: 1024px) {
  #carouselBox img {
    height: 400px;
  }

}

@media only screen and (min-device-width: 1280px) {
  #carouselBox img {
    height: 600px;
  }

}

#outer {

  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.center {
  text-align: center;
}

.border-radiusImportant {
  border-radius: 15px !important;
  background-color: #fff;
  opacity: .8;
}

.centerimage {
  display: block;
  margin: 0 auto;
}

.textboxCenter {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.cursiveClass {
  /* font-family: cursive; */
}

.imageBackgroundLogin {
  background-image: url('./assets/images/loginBackgroundNew.png');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.backGroundColor {
  background-color: #206CB3;
  opacity: 1
}

.imageBackgroundRegister {

  background-image: url('./assets/images/registerBackground.png');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.imageBackgroundForgotPassword {

  background-image: url('./assets/images/forgotPassword.png');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.scroll-downs {
  position: absolute;
  right: 0;
  margin: auto;
  float: right;
  width: 34px;
  height: 55px;
}

.mousey {
  width: 0px;
  padding: 13px 12px;
  height: 7px;
  border: 2px solid black;
  border-radius: 25px;
  opacity: 0.55;
  box-sizing: content-box;
}

.scroller {
  width: 2px;
  height: 6px;
  border-radius: 25%;
  background-color: blue;
  animation-name: scroll;
  animation-duration: 3.2s;
  animation-timing-function: cubic-bezier(.15, .41, .69, .94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }

  10% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(5px);
    opacity: 0;
  }
}


.icon-scroll,
.icon-scroll:before {
  position: absolute;
  right: 5px;
  margin: auto;
}

.icon-scroll {
  width: 25px;
  height: 35px;
  top: 72%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px black;
  border-radius: 25px;
}

.icon-scroll:before {
  content: '';
  width: 4px;
  height: 4px;
  background: black;
  top: 5px;
  right: 10px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.nunito-font-form {
  color: #000;
  font-size: 0.875rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  /* 142.857% */
}

.input-placeholder-top::placeholder {
  line-height: normal; /* This sets the line-height to a normal value */
  padding-top: 4px; /* Adjust as needed */
  vertical-align: top;
}